<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters page-list-header">
                <div class="col l-6 m-6 c-12">
                    <h4 class="page-list-title"><md-icon>note_add</md-icon> <span>{{title}}</span></h4>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="page-list-action">
                        <md-button v-shortkey="['ctrl', 'l']" @shortkey="submit()" @click="submit()" class="md-raised md-accent"><span>L</span>ưu<md-tooltip>Lưu (Ctrl + L)</md-tooltip></md-button>
                        <md-button v-shortkey="['ctrl', 'i']" @shortkey="back()" @click="back()" class="md-raised">Quay lạ<span>i</span><md-tooltip>Quay lại (Ctrl + Q)</md-tooltip></md-button>
                    </div>
                </div>
            </div>
            <div class="page-list-body">
                <div class="form-body">
                    <div class="row">
                        <div class="col l-6 m-6 c-12">
                            <md-field :class="{'md-invalid': submitted && $v.entity.groupId.$error }">
                                <label for="groupId">Nhóm loại</label>
                                <md-select v-model="entity.groupId" name="groupId" id="groupId">
                                    <md-option value="0">Chọn nhóm loại</md-option>
                                    <md-option v-for="g in groups" :key="g.value" :value="g.value">{{g.text}}</md-option>
                                </md-select>
                                <span class="md-error" v-if="submitted && !$v.entity.groupId.isSelected">Vui lòng chọn nhóm loại</span>
                            </md-field>

                            <md-field :class="{'md-invalid': submitted && $v.entity.code.$error }">
                                <label for="code">Code</label>
                                <md-input name="code" v-model="entity.code"></md-input>
                                <span class="md-error" v-if="submitted && !$v.entity.code.required">Vui lòng nhập code</span>
                            </md-field>

                            <md-field :class="{'md-invalid': submitted && $v.entity.name.$error }">
                                <label for="name">Tên</label>
                                <md-input name="name" v-model="entity.name"></md-input>
                                <span class="md-error" v-if="submitted && !$v.entity.name.required">Vui lòng nhập tên</span>
                            </md-field>

                            <div class="form-control">
                                <md-radio v-model="entity.isActive" :value="true">Hoạt động</md-radio>
                                <md-radio v-model="entity.isActive" :value="false">Khóa</md-radio>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
   </div>
</template>
<script>
    import typeService from '../../../api/typeService';
    import messageBox from '../../../utils/messageBox';
    import { required } from 'vuelidate/lib/validators';
    import { mapActions } from 'vuex';

    export default ({
        metaInfo: {
            title: 'Thêm/Cập nhật code'
        },
        data() {
            return {
               title: '',
               id: 0,
               submitted: false,
               entity: { id: 0, groupId: 0, code: '', name: '', isActive: true },
               groups: []
            }
        },
        created(){
            this.id = this.$route.params.id;
            if(this.id > 0){
               this.title = 'Cập nhật code';
               this.getById();
            }
            else{
               this.title = 'Thêm mới code';
            }
            this.getGroups();
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            submit(){
                this.submitted = true;
                this.$v.$touch();
                if (this.$v.$invalid) {
                    return;
                }
                if(this.id > 0){
                    this.edit();
                }
                else{
                    this.add();
                }
            },

            add(){
                this.setLoading(true);
                typeService.add(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Thêm mới thành công");
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                 }).finally(() => { this.setLoading(false); });
            },

            edit(){
                this.setLoading(true);
                typeService.edit(this.entity).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Cập nhật thành công");
                        this.$router.push('/type');
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getById(){
                this.setLoading(true);
                typeService.getById(this.id).then((response) => {
                    if(response.statusCode == 200){
                        this.entity = response.data;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getGroups(){
                this.groups.push({ value: 1, text: 'Nhóm hàng hóa' });
                this.groups.push({ value: 2, text: 'Loại nhân viên' });
                this.groups.push({ value: 3, text: 'Loại đối tác - khách hàng' });
                this.groups.push({ value: 4, text: 'Trạng thái phương tiện' });
                this.groups.push({ value: 5, text: 'Loại bảo hiểm' });
                this.groups.push({ value: 6, text: 'Loại dự án' });
                this.groups.push({ value: 7, text: 'Loại hợp đồng' });
                this.groups.push({ value: 8, text: 'Vị trí lốp' });
                this.groups.push({ value: 9, text: 'Nhóm địa điểm' });
                this.groups.push({ value: 10, text: 'Nhóm tuyến đường' });
                this.groups.push({ value: 11, text: 'Nguồn vật tư sửa chữa' });
                this.groups.push({ value: 12, text: 'Cấp sửa chữa' });
                this.groups.push({ value: 14, text: 'Loại đề nghị sửa chữa' });
                this.groups.push({ value: 15, text: 'Đề nghị sửa chữa' });
                this.groups.push({ value: 16, text: 'Loại hàng hóa' });
                this.groups.push({ value: 17, text: 'Trạng thái lệnh điều xe' });
                this.groups.push({ value: 18, text: 'Trạng thái hành trình' });
                this.groups.push({ value: 19, text: 'Đơn vị vật tư sửa chữa' });
                this.groups.push({ value: 20, text: 'Loại khai báo hải quan' });
            },

            back(){
                this.$router.push('/type');
            },
        },
        validations: {
            entity: {
                groupId: {
                    required,
                    isSelected(value) {
                        if (value > 0) {
                            return true;
                        }
                        else{
                            return false;
                        }
                    }
                },
                code: { required },
                name: { required },
            }
       }
    })

</script>
